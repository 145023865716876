input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 576px) {
  .mobile-input {
    width: 162px !important;
  }
}
