.full-vh {
  min-height: 100vh;
}

.label-text {
  text-align: left;
  display: block;
}

.char-limit {
  text-align: right;
  display: block;
}

.spinning {
  animation: rotate 1.5s linear infinite;
  animation-delay: 0;
}
.loading-logo {
  height: 50px;
  width: 50px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
