.account-pages {
  padding-top: 2rem;
}

/* Styling for the card */
.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.card-title {
  font-size: 1.75rem; /* Increased font size */
  font-weight: 600;
}

/* Short description text */
p.text-muted {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

/* Logo styling */
.logo-img {
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
}

/* Styling for radio button container */
.radio-option {
  margin-bottom: 1rem;
  padding: 0.25rem;
}

/* Custom styling for the radio label */
.custom-radio-label {
  display: flex;
  align-items: center;
  font-size: 1rem; /* Adjust font size if needed */
  cursor: pointer;
}

/* Make radio input bigger */
.custom-radio-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  cursor: pointer;
}

/* Styling for when the radio button is checked */
.custom-radio-input:checked {
  background-color: #ff7000; /* Primary color */
  border-color: #ff7000;
}

/* Make the radio button circle more prominent */
.custom-radio-input[type="radio"] {
  border-radius: 50%;
  border: 2px solid #ff7000;
  appearance: none;
  outline: none;
  transition: all 0.3s ease;
}

/* When the radio button is focused */
.custom-radio-input:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Button styling */
.btn-custom {
  background-color: #ff7000;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: #0056b3;
}

.spinning {
  animation: rotate 1.5s linear infinite;
  animation-delay: 0;
}
.loading-logo {
  height: 50px;
  width: 50px;
}

.authCodeInput {
  width: 50px;
  height: 50px;
  padding: 6px;
  border-radius: 8px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border: 1px solid #ced4da;
  text-transform: uppercase;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .local-number-add-button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    height: 46px;
    width: 46px;
    border-radius: 23px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    span {
      display: none;
    }
    i {
      font-size: 24px;
      margin-right: 0 !important;
    }
  }
}
