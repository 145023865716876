@import "assets/scss/theme.scss";

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.dialog {
  box-shadow: 0 8px 6px -6px white;
  position: static;
  left: 20;
  bottom: -35%;
  border-radius: 50%;
}

.image {
  width: 256px;
  height: 256px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .image {
    width: 200px;
    height: 200px;
  }
}
