.settings-panel-card-body {
  padding: 20px;
}

.adjust-button {
  margin-bottom: -14px;
}
.spinning {
  animation: rotate 1.5s linear infinite;
  animation-delay: 0;
}
.loading-logo {
  height: 50px;
  width: 50px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .settings-panel {
    padding: 0 !important;
  }
  .settings-panel-card-body {
    padding: 0 !important;
    ul {
      padding: 10px 0px !important;
    }
  }

  input.form-control {
    margin-right: 30px;
    margin-left: 10px;
    width: 93%;
  }

  .all-customer-card-body {
    padding: 0 !important;
    .mdb-datatable-filter {
      width: 100%;
    }
  }

  .start-date .end-date {
    padding-left: "20px";
    padding-right: "20px";
  }

  .all-customers-start-date {
    margin-bottom: 10px !important;
  }

  .all-customers-add-button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    height: 46px;
    width: 46px;
    border-radius: 23px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    span {
      display: none;
    }
    i {
      font-size: 24px;
      margin-right: 0 !important;
    }
  }
}
