.fontColor {
  background-color: #ff7000;
}
.spinning {
  animation: rotate 1.5s linear infinite;
  animation-delay: 0;
}
.loading-logo {
  height: 50px;
  width: 50px;
}
.card-title {
  margin-top: 10px;
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .round-button {
    border-radius: 40%;
  }
  .purchase-history {
    display: none;
  }
  .local-number-add-button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    height: 46px;
    width: 46px;
    border-radius: 23px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    span {
      display: none;
    }
    i {
      font-size: 24px;
      margin-right: 0 !important;
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
