.spinning {
  animation: rotate 1.5s linear infinite;
  animation-delay: 0;
}
.loading-logo {
  height: 50px;
  width: 50px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1024px) {
  .location-header {
    margin-left: 40px;
  }
  .location-span {
    margin-left: 45px;
  }
}

@media (max-width: 576px) {
  .collapsible-mobile-container {
    margin-right: 0;
    overflow-x: auto;
    .location-container {
      position: sticky;
      left: 0;
      .location-header {
        padding-left: 2px;
        margin-left: 0;
      }
      .location-list {
        padding-left: 2px;
      }
    }
  }
}
