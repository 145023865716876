.img-btn {
  border: none;
  background-color: #fff;
  height: 140%;
  width: 200%;
}

.upload-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35%;
  transform: translate(130px, 20px);
}

.camera-icon {
  cursor: pointer;
}

.profile-photo-input-container {
  position: absolute;
  top: 0;
  right: 5 !important;
}

.profile-photo-input {
  margin-left: 0px;
  margin-right: 0px;
}

.no-display {
  display: none;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.column-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .mobile-column-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .upload-btn {
    transform: translate(100px, 20px);
  }
}
